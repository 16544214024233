<template>
  <fieldset style="position: absolute; height: 230px; width: 648px">
    <legend>
      Selección/Acotación
      <fa-icon
        :icon="['fas', 'cog']"
        style="cursor: pointer; color: #888"
        @click="abrirConfiguracion"
        v-tooltip="'Configurar campos del filtro de selección/acotación'"
      />
    </legend>
    <field
      ref="seleccion"
      name="seleccion"
      widget="handsontable"
      :height="205"
      :minRows="8"
      style="left: 4px; width: 290px"
      :htSettings="htSettingsFiltro"
      :columns="[
        { name: 'nombre', header: 'Campo', readOnly: true },
        { name: 'valor', header: 'Valor' },
      ]"
    />
    <field
      ref="acotacion"
      name="acotacion"
      widget="handsontable"
      :height="205"
      :minRows="8"
      style="left: 300px; width: 345px"
      :htSettings="htSettingsFiltro"
      :columns="[
        { name: 'nombre', header: 'Campo', readOnly: true },
        { name: 'desde', header: 'Desde' },
        { name: 'hasta', header: 'Hasta' },
      ]"
    />
  </fieldset>
</template>
<script>
import rFormMixin from "./../components/rFormMixin.vue";

export default {
  mixins: [rFormMixin],
  props: {
    referencia: { type: String, required: true },
    model: { type: String, required: true },
    nombre: { type: String, required: true },
  },
  data: function () {
    return {
      dbAdapter: "filtro_seleccion_acotacion",
      primary: "id",
      mode: "new",
      htSettingsFiltro: {
        cells: function (row, col, prop) {
          var cellProperties = {};
          let h = this.instance;
          let data = h.getSourceData()[row];
          if (!data.campo) cellProperties.readOnly = true;
          if (['valor', 'desde', 'hasta'].includes(this.prop)) {
            switch (data.tipo) {
              case 'date':
                cellProperties.type = 'date2';
                break;
              case 'boolean':
                cellProperties.type = 'checkbox';
                break;
            }
          }
          return cellProperties;
        }
      }
    };
  },
  methods: {
    abrirConfiguracion() {
      var self = this;
      self.app.openChildWindow("filtro_seleccion_acotacion_config", self.win, {
        backdrop: true,
        model: self.model,
        props: {
          referencia: self.referencia,
          model: self.model,
          nombre: self.nombre,
          defaultMode: "edit",
          onSave: function (lineas) {
            //alert("saved!");
            this.$emit("close");
            self.loadFilter();
            /*
            APP.LOGIC.filtro.loadFiltro(win,opts.referencia);
            win_popup.close();
            */
          },
        },
      });
    },
    loadFilter() {
      var self = this;
      window.DB.get("filtro_seleccion_acotacion", {
        itemId: self.referencia,
        fields: ["campos_seleccion", "campos_acotacion"],
      }).then((data) => {
        window.DB.action("bd", "getCampos", {
          data: { model: self.model },
        }).then(({ data: campos }) => {
          self.$set(
            self.formData,
            "seleccion",
            (data.campos_seleccion?.split(",") || []).map((c) => ({
              campo: c,
              nombre: c in campos ? campos[c].title || c : c,
              tipo: c in campos ? campos[c].type || '' : '',
            }))
          );
          self.$set(
            self.formData,
            "acotacion",
            (data.campos_acotacion?.split(",") || []).map((c) => ({
              campo: c,
              nombre: c in campos ? campos[c].title || c : c,
              tipo: c in campos ? campos[c].type || '' : '',
            }))
          );
        });
      });
    },
    getFilter() {
      var self = this;
      var res = [];
      (self.formData.seleccion || []).forEach((a) => {
        if (a.valor) res.push([a.campo, "*LIKE*", a.valor]);
      });
      (self.formData.acotacion || []).forEach((a) => {
        if (a.desde) res.push([a.campo, ">=", a.desde]);
        if (a.hasta) res.push([a.campo, "<=", a.hasta]);
      });
      return res;
    },
    rendererValor() {

    },

  },
  mounted() {
    this.loadFilter();
  },
};
</script>