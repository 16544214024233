<script>
export default {
  inject: ["app", "win"],
  provide: function () {
    return { rformc: this };
  },
  watch: {
    loading: function (newVal) {
      if (newVal) {
        this.$el.classList.add("form-loading");
      } else {
        this.$el.classList.remove("form-loading");
      }
    },
    mode: function (newVal, oldVal) {
      var self = this;
      //if (!self.initialFormData) return;
      if (newVal == "new") {
        self.formData = {
          ...self.$utils.misc.clone(self.defaultData),
          ...self.$utils.misc.clone(self.initialFormData),
        };
        if (self.sequence && !self.sequence.cancel) self.updateSequence();
        if (self.$refs["main-tabs"]) self.$refs["main-tabs"].active(0);
      } else if (newVal == "search") {
        if (self.htSettings) {
          self.setFilter(self.getFilter());
          self.initRemoteData();
          if (self.$refs["main-collapse"]) self.$refs["main-collapse"].open();
        }
      }
      // Autofocus
      self.autoFocusFirst();
    },
  },
  data() {
    return {
      self: this,
      defaultData: {},
      defaultDataSearch: {},
      formData: {}, //this.$utils.misc.clone(this.initialFormData) || {},
      lastFormData: {},
      errors: {},
      filterConfig: {},
      itemId: this.initialId,
      loading: false,
      mode: this.initialMode,
      fields: [],
      primary: "id",
      sequence: null,
      dbAdapter: "",
      log: true,
      validation: function (data, resolve) {
        resolve(data);
      },
      beforeSave: function (resolve) {
        var self = this;
        resolve(self.formData);
      },
      atajo_anadir: true,
      atajo_modificar: true,
      atajo_buscar: true,
    };
  },
  props: {
    initialFormData: { type: Object, default: () => { } },
    initialMode: { type: String, default: "search" },
    defaultMode: { type: String, default: "view" },
    onLoad: { type: Function, default: () => { } },
    onSave: { type: Function, default: () => { } },
    onCancel: { type: Function, default: () => { } },
    initialId: String,
    /*classContent: {},
    dbAdapter: {
      type: Object
    },
    primary: {
      type: String,
      default: "id"
    },
    defaultData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    initialData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    validation: {
      type: Function,
      default: function(data, resolve) {
        resolve(data);
      }
    },
    beforeSave: {
      type: Function,
      default: function(resolve) {
        var self = this;
        resolve(self.formData);
      }
    }*/
  },
  mounted() {
    var self = this;
    self.$set(self.win, "subtitle", self.mode);

    if (self.winOptions && self.winOptions.itemId) {
      self.loadItem(self.winOptions.itemId);
    } else if (self.itemId) {
      self.loadItem(self.itemId);
    }
    // Sequence
    ((self.sequence && self.sequence.params) || []).forEach(function (v) {
      self.$watch("formData." + v, function (newValue, oldValue) {
        if (self.mode != "new" || self.sequence.cancel) return;
        self.updateSequence();
      });
    });
    //setTimeout(function() {
    self.autoFocusFirst();
    //}, 300);

    self.$el.addEventListener("keydown", function (e) {
      if (e.key == "Tab" && self.$refs["main-tabs"]) {
        if (e.noCambiarTab) return;
        if (e.shiftKey) self.$refs["main-tabs"].prevTab();
        else self.$refs["main-tabs"].nextTab();
      }
    });
    self.$nextTick(function () {
      if (self.initialFormData) {
        self.formData = {
          ...self.$utils.misc.clone(self.defaultData),
          ...self.$utils.misc.clone(self.initialFormData),
        };
        if (self.initialMode == 'new') self.updateSequence();
        //self.$set(self, "formData", self.initialFormData);
      }
    });
  },
  methods: {
    autoFocusFirst() {
      var self = this;

      setTimeout(function () {
        self.$nextTick(function () {
          let $parent = window.$(self.$el).find("article");
          let $focusables = self.$utils.misc.getFocusables($parent);
          if (!$focusables.length) return;
          let $af = $focusables.filter(".autofocus *");
          if ($af.length) {
            $af[0].focus();
          } else {
            /*if ($focusables[1]) $focusables[1].focus();
            else $focusables[0].focus();*/
            if ($focusables[0]) $focusables[0].focus();
          }
        });
      }, 500);
    },
    getFormData(str) {
      var self = this;
      var namespaces = str.split(".");
      var context = self.formData;
      for (var i = 0; i < namespaces.length; i++) {
        if (typeof context == "undefined" || !context) {
          let n = namespaces[i];
          if (0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n)) {
            //Número entero positivo
            context = [];
          } else {
            context = {};
          }
        }
        context = context[namespaces[i]];
      }
      return context || [];
    },
    updateSequence: function () {
      var self = this;
      let name =
        typeof self.sequence.name == "function"
          ? self.sequence.name()
          : self.sequence.name;
      if (!name) return;
      window.DB.action("secuencia", "getSequence", {
        data: {
          name:
            typeof self.sequence.name == "function"
              ? self.sequence.name()
              : self.sequence.name,
          params: (self.sequence.params || []).reduce(
            (map, v) => ({
              ...map,
              [v.split(".")[0]]: self.getFormData(v),
            }),
            {}
          ),
        },
      })
        .then(function (res) {
          if (res.success) {
            self.$set(
              self.formData,
              self.sequence.field || self.primary,
              res.data
            );
          }
        })
        .catch(function (e) {
          window.console.log(e);
        });
    },
    cancelMode: function () {
      var self = this;
      switch (self.mode) {
        case "edit":
          self.formData = JSON.parse(JSON.stringify(self.lastFormData));
          self.setMode("view");
          break;
        case "new":
          self.setMode("empty");
          break;
        case "search":
          self.setMode("empty");
          break;
        default:
      }
      self.onCancel.call(self);
      self.$emit("cancel");
    },
    setMode: function (mode) {
      var self = this;
      if (mode == 'empty') mode == 'search';
      self.$emit('setMode', mode);
      switch (mode) {
        case "view":
        case "edit":
          if (!self.itemId) return;
          break;
        case "new":
          if (self.mode == "edit") return;
          self.itemId = null;
          self.clear();
          if (self.sequence) self.sequence.cancel = false;
          if (self.$refs["main-collapse"]) self.$refs["main-collapse"].close();
          break;
        case "search":
          if (self.mode == "new" || self.mode == "edit") return;
          self.itemId = null;
          self.formData = self.$utils.misc.clone(self.defaultDataSearch);
          break;
        default:
          self.itemId = null;
          self.clear();
          mode = "empty";
      }
      self.mode = mode;
      self.errors = {};
      self.$set(self.win, "subtitle", mode);
    },
    validate: function (data) {
      var self = this;
      return new Promise(function (resolve, reject) {
        self.validation(data, resolve, reject);
      });
    },
    beforeSaveFn: function () {
      var self = this;
      return new Promise(self.beforeSave.bind(self));
    } /*);
          if (mime && mime.length) {
            //es base64
            var type = mime[1];
            var blob = self.base64toBlob(input.split(",")[1], type);
            //Crear Form
            var formData = new FormData();
            formData.append("files[]", blob);
            p = p.then(res => {
              return new Promise(function(resolve, reject) {
                return app.request
                  .promise({
                    url: self.urlAttachments,
                    method: "POST",
                    data: formData,
                    processData: true,
                    dataType: "json"
                  })
                  .then(function(res2) {
                    if (res2.data.success && res2.data.data.length) {
                      parent[key] = res2.data.data[0].url;
                      return resolve(res2);
                    } else {
                      return reject({
                        message: self.$t("rform.error_subiendo_archivos")
                      });
                    }
                  });
              });
            });
          }
        }
      };
      iterate(self.formData);
      p.then(function(res) {
        return res;
      });
      return p;
    },*/,
    /*base64toBlob: function(base64, type) {
      const byteCharacters = atob(base64);
      const byteArrays = [];
      const sliceSize = 512;
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: type });
    },
    uploadAttachments: function() {
      var self = this;
      var app = self.$f7;
      var promises = [];
      var p = Promise.resolve({ data: { success: true } });
      var iterate = function(input, parent, key) {
        if (Array.isArray(input)) {
          input.forEach(function(el, idx) {
            iterate(el, input, idx);
          });
        } else if (typeof input == "object") {
          for (let el in input) {
            iterate(input[el], input, el);
          }
        } else if (typeof input == "string") {
          var mime = input.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
    loadItem: function (itemId) {
      var self = this;
      var app = self.$f7;
      if (self.mode === "new" || self.mode === "edit") {
        self.app.toast(
          "Para cargar un registro debe cancelar la edición",
          "error"
        );
        return;
      }
      self.loading = true;
      window.DB.get(self.dbAdapter, {
        itemId,
        fields: self.fields,
        log: self.log,
      })
        .then(function (res) {
          self.$set(self, "formData", res);
          self.lastFormData = JSON.parse(JSON.stringify(res));
          self.itemId = res[self.primary];
          self.setMode(self.defaultMode);
          self.onLoad.call(self, self.itemId, res);
          self.$nextTick(() => self.$emit("loadItem"));
        })
        .catch(function (e) {
          window.console.log(e);
          self.app.toast(e.msg, "error");
        })
        .finally(function () {
          self.loading = false;
        });
    },
    saveItem() {
      var self = this;
      var app = self.$f7;
      if (self.mode != "new" && self.mode != "edit") return;
      self.loading = true;
      self
        .beforeSaveFn()
        .then((res) => self.validate(res))
        .then(function (data) {
          //window.console.log(data);
          return window.DB.save(self.dbAdapter, {
            data: data,
            itemId: self.itemId,
            log: self.log,
          });
        })
        .then(function (res) {
          //self.app.playSound("success");
          self.app.toast("Registro guardado con éxito", "success");
          self.errors = {};
          self.itemId = res.id;
          //TODO: get
          window.DB.get(self.dbAdapter, {
            itemId: self.itemId,
            fields: self.fields,
          })
            .then(function (res) {
              self.$set(self, "formData", res);
              self.lastFormData = JSON.parse(JSON.stringify(res));
              self.setMode("view");
              if (self.htSettings) self.initRemoteData();
              self.$nextTick(() => {
                self.$emit("save", res);
                self.app.$emit("rform-save", {
                  model: self.dbAdapter,
                  id: self.itemId,
                  data: res,
                });
              });
              self.onSave.call(self, self.itemId, res);
            })
            .catch(function (e) {
              window.console.log(e);
              if (typeof e == "string") {
                e = {
                  msg: e,
                };
              }
              self.app.toast(e.msg, "error");
            })
            .finally(function () {
              self.loading = false;
            });
        })
        .catch(function (e) {
          window.console.log(e);
          if (typeof e == "string") {
            e = {
              msg: e,
            };
          }
          self.app.toast(e.msg, "error");
          self.errors = e.data || {};
        })
        .finally(function () {
          self.loading = false;
        });
    },
    deleteItem() {
      var self = this;
      self.app.confirm(
        "¿Seguro que deseas eliminar este registro?",
        function () {
          window.DB.delete(self.dbAdapter, {
            itemId: self.itemId,
          })
            .then(function (res) {
              if (self.htSettings) self.initRemoteData();
              self.app.toast("Registro eliminado con éxito", "success");
              self.clear();
              self.setMode("empty");
            })
            .catch(function (res) {
              self.app.toast(res.error[0], "error");
            });
        }
      );
    },
    clear: function () {
      var self = this;
      //self.$set(self, 'formData', {});
      self.formData = {};
      return;

      /*for (const prop of Object.getOwnPropertyNames(this.formData)) {
        let v = this.formData[prop];
        if (v && typeof v == "object" && v.constructor === Array) {
          self.$set(self.formData, prop, this.defaultData[prop] || []);
        } else if (v && typeof v == "object" && v.constructor === Object) {
          self.$set(self.formData, prop, this.defaultData[prop] || {});
        } else if (v && typeof v == "object" && v.constructor === Date) {
          //TODO: this.formData[prop] = null;
          self.$set(self.formData, prop, this.defaultData[prop] || []);
        } else {
          self.$set(self.formData, prop, this.defaultData[prop] || null);
        }
      }*/
    },
    getFilter: function () {
      var self = this;
      var res = [];

      /*let iterateObj = function(o, pre = null) {
        for (let f in o) {
          let d = o[f];
          let s = pre ? pre + "." + f : f;
          let fc = self.filterConfig[s] || {};
          if (d && typeof d === "object" && d.constructor == Object) {
            let searchField = fc.searchField || "id";
            let operation = fc.operation || "=";
            if (!d[searchField]) continue;
            iterateObj(d, s);
          } else if ((d && typeof d === "string") || d instanceof String) {
            let operation = fc.operation || "*LIKE*";
            res.push([f, operation, d]);
          } else if (Number.isInteger(d)) {
            let operation = fc.operation || "*LIKE*";
            res.push([f, operation, d]);
          }
        }
      };
      iterateObj(self.formData);*/

      for (let f in self.formData) {
        let d = self.formData[f];
        let fc = self.filterConfig[f] || {};
        if (fc instanceof Function) {
          fc(res);
        } else if (d && typeof d === "object" && d.constructor == Object) {
          //window.console.log(f, d, fc);
          // M2o
          let searchField = fc.searchField || "id";
          let operation = fc.operation || "=";
          if (!d[searchField]) continue;

          res.push([f + "." + searchField, operation, d[searchField]]);
        } else if (d && Array.isArray(d) && d.length) {
          let searchField = fc.searchField || "codigo";
          let operation = fc.operation || "IN";
          res.push([f + "." + searchField, operation, d.map(x => x[searchField])]);
        } else if ((d && typeof d === "string") || d instanceof String) {
          let operation = fc.operation || "*LIKE*";
          res.push([f, operation, d]);
        } else if (Number.isInteger(d)) {
          let operation = fc.operation || "*LIKE*";
          res.push([f, operation, d]);
        }
      }
      //window.console.log(res);
      return res;
    },
  },
};
</script>
<style>
.window>section>article {
  height: 335px;
  width: 670px;
  margin: 10px;
  border: solid 1px #ccc;
  position: relative;
  box-sizing: border-box;
}

.window {
  overflow: visible;
}

@keyframes fadein {
  from {
    background-color: transparent;
  }

  to {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.form-loading:before {
  position: absolute;
  display: flex;
  align-items: top;
  justify-content: center;
  content: "Cargando...";
  padding-top: 5px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: fadein 1s linear 1;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99999;
}
</style>
