<template>
  <div>
    <window-header></window-header>
    <section>
      <article style="height:395px;width:770px">
        <div class="emision-sidebar">
          <button :disabled="!formData.plantilla" @click="emitir(false)">
            <fa-icon icon="file-alt" size="2x" />
            Visualizar
          </button>
          <button :disabled="!formData.plantilla" @click="emitir(true)">
            <fa-icon icon="print" size="2x" />
            Imprimir
          </button>
          <button @click="app.openWindow('disenar_documentos', { props: { model } })">
            <fa-icon icon="paint-brush" size="2x" />
            Diseñar
          </button>
          <button :disabled="!formData.plantilla">
            <fa-icon icon="envelope" size="2x" />
            Enviar por email
          </button>
        </div>
        <fieldset style="position: absolute; left: 110px; top: 5px; height: 62px; width: 648px">
          <legend>Imprimir</legend>
          <field name="plantilla" dbAdapter="plantilla_informe" :filter="[['model', '=', model]]" widget="m2o"
            width="600px" style="top:9px; left:17px;" />
        </fieldset>
        <fieldset style="position: absolute; left: 110px; top: 70px; height: 62px; width: 300px">
          <legend>Datos de Selección</legend>
          <field name="seleccion1" widget="radio" :options="{
            'Pendientes de emitir': 'pendientes',
            'Emitidos': 'emitidos',
            'Todos': 'todos'
          }" inline-label style="top: 6px; left: 20px" />
        </fieldset>
        <filtro-seleccion-acotacion ref="selaco" style="left: 110px; top: 150px;"
          :referencia="'emitir_documento_' + model" :model="model" :nombre="'Emitir documento. ' + subtitulos[model]" />
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import FiltroSeleccionAcotacion from "./../components/FiltroSeleccionAcotacion.vue";

// TODO: default plantilla

let subtitulos = {
  factura_cliente: 'Facturas de cliente',
  albaran_cliente: 'Albaranes de cliente',
  pedido_cliente: 'Pedidos de cliente',
};

export default {
  mixins: [WindowMixin, rFormMixin],
  components: { FiltroSeleccionAcotacion },
  props: {
    model: {
      required: true,
      type: String
    }
  },
  data: function () {
    var self = this;
    return {
      title: "Emitir documento: " + subtitulos[self.model],
      mode: 'new',
      dbAdapter: "xxx",
      primary: "codigo",
      formData: { seleccion1: 'todos', plantilla: self.app.config['plantilla_documento_' + self.model] },
      subtitulos
    };
  },
  mounted: function () {
    //var $ = window.$;
  },
  methods: {
    emitir: function (print = false) {
      let self = this;
      let obj = {
        id: self.formData.plantilla.codigo,
        print: print ? 1 : null,
        conditions: self.$refs.selaco.getFilter(),
        token: window.session.token
      }
      let params = window.$.param(obj);
      window.open(
        self.app.urlGeneradorInformesDocumentoPrint + "&" + params,
        "_blank"
      );
      //self.$emit("close");
    }
  },
};
</script>
<style>
.emision-sidebar {
  display: flex;
  flex-direction: column;
  width: 100px;
}

.emision-sidebar button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: var(--padding-half);
  padding: var(--padding-half);
}
</style>
